import axios from 'axios';

const client = axios.create();

export const FILE_SERVER_URL =
  process.env.REACT_APP_ENVIRONMENT !== 'production'
    ? 'https://api.dev.groro.co.kr/api'
    : 'https://api.groro.co.kr/api';

console.log(process.env.NODE_ENV);
console.log(FILE_SERVER_URL);

client.defaults.baseURL = FILE_SERVER_URL;

client.interceptors.request.use(
  async function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

export default client;
